import React from 'react'
import { NavLink } from 'react-router-dom'
import Select from '../components/inputs/Select'
import JobAdvert from '../components/JobAdvert'
import { ReactComponent as Refresh } from '../assets/img/icon/refresh.svg'
import { ReactComponent as SaveIcon } from '../assets/img/icon/save-01.svg'
import { ReactComponent as Search } from '../assets/img/icon/search.svg'
import { ReactComponent as Update } from '../assets/img/icon/update.svg'
import { ReactComponent as ArrowDown } from '../assets/img/icon/arrow-down.svg'
import { ReactComponent as XCircle } from '../assets/img/icon/x-circle.svg'
import { ReactComponent as LightStatus } from '../assets/img/icon/light-status.svg'

import GButton from '../components/inputs/GButton'
import SwitchCheckbox from '../components/inputs/SwitchCheckbox'
import TalentItem from '../components/TalentItem'

function TalentPage() {
  const options = [
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
  ]
  const state = [
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
  ]

  const [value, setValue] = React.useState('fruit')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <>
      <div className='filter-talent'>
        <div className='filter-talent-head'>
          <div className='filter-talent-head-title'>Select role</div>
          <div className='filter-talent-head-select'>
            <Select options={options} value={value} onChange={handleChange} />
          </div>
          <div className='filter-talent-head-btn'>
            <button className='filter-talent-head-btn-item'>
              Core Filters <ArrowDown />
            </button>
            <button className='filter-talent-head-btn-item'>
              Open to Filters <ArrowDown />
            </button>
          </div>
          <div className='filter-talent-head-traffic'>
            <div className='filter-talent-head-traffic-text'>
              Traffic Light Status
            </div>
            <div className='filter-talent-head-traffic-icon'>
              <LightStatus />
              <LightStatus />
              <LightStatus />
            </div>
          </div>
          <div className='filter-talent-head-switch'>
            <div className='filter-talent-head-switch-text'>Waved</div>
            <SwitchCheckbox />
          </div>
          <div className='filter-talent-head-select'>
            <Select options={options} value={value} onChange={handleChange} />
          </div>
          <GButton text={'Update Results'} customIcon={<Update />} />
        </div>
        <div className='filter-talent-filters'>
          <div className='filter-talent-filters-top'>
            <div className='filter-talent-filters-top-title'>Core</div>
            <button className='filter-talent-filters-top-close'>
              Close <XCircle />
            </button>
          </div>
          <div className='filter-talent-filters-selects'>
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
          </div>
        </div>
        <div className='filter-talent-filters'>
          <div className='filter-talent-filters-top'>
            <div className='filter-talent-filters-top-title'>Core</div>
            <button className='filter-talent-filters-top-close'>
              Close <XCircle />
            </button>
          </div>
          <div className='filter-talent-filters-selects'>
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
            <Select options={options} value={value} onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className='link mt-20'>
        <NavLink to='/'>10 Talents match your search</NavLink>
      </div>
      <div className='list mt-20'>
        <TalentItem />
      </div>
    </>
  )
}

export { TalentPage }
