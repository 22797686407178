import React from 'react'
import { ReactComponent as SearchWhite } from '../../assets/img/icon/search-white.svg'

export default function DButton({ type = 'button', text }) {
  return (
    <button type={type} className='d-button'>
      {text}
    </button>
  )
}
