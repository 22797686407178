import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg'

function AdminTalentSettings() {
  return (
    <>
      <NavLink className='link-back' to={'/settings'}>
        <ArrowLeft />
        Back to all settings
      </NavLink>
      <table className='g-table'>
        <tr>
          <th>Talent Name/Reference</th>
          <th>Email</th>
          <th>Last Active</th>
          <th>Actoins</th>
        </tr>
        <tr>
          <td>
            <div className='g-table-user'>
              <div className='g-table-user-img'>
                <img
                  src={require(`../../assets/img/companies/aviva-logo.jpg`)}
                  alt=''
                />
              </div>
              <div className='g-table-user-text'>
                <div className='g-table-user-title'>Johnny Lowe #653786</div>
                <NavLink>View in Talent</NavLink>
              </div>
            </div>
          </td>
          <td>hi@johnnylowe.design</td>
          <td>01/06/2024</td>
          <td>
            <div className='t-btn-con'>
              <NavLink>Reset Password</NavLink>
              <NavLink>Delete Account</NavLink>
            </div>
          </td>
        </tr>
      </table>
    </>
  )
}

export { AdminTalentSettings }
