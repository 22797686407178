import React from 'react'
import SmallButton from './inputs/SmallButton'
import GButton from './inputs/GButton'
import { ReactComponent as Location } from '../assets/img/icon/location.svg'
import { ReactComponent as Stars } from '../assets/img/icon/stars.svg'
import { ReactComponent as Dislike } from '../assets/img/icon/dislike.svg'
import { ReactComponent as Apply } from '../assets/img/icon/apply.svg'
import { ReactComponent as MessageTyping } from '../assets/img/icon/message-typing.svg'
import { ReactComponent as Hend } from '../assets/img/icon/hend.svg'
import MultiTag from './MultiTag'
export default function AdminNotificationsItem() {
  return (
    <div className='notif-item admin-notif-item'>
      <div className='notif-item-left'>
        <div className='notif-item-img'>
          <img src={require(`../assets/img/companies/aviva-logo.jpg`)} alt='' />
        </div>
        <div className='notif-item-text'>
          <div className='notif-item-text-title'>Aviva Investors</div>
          <div className='notif-item-text-label'>Message Centre</div>
        </div>
      </div>
      <div className='notif-item-mid'>
        <div className='notif-item-posted'>
          <div className='notif-item-posted-title'>
            Posted on <span>01/04/24</span>
          </div>
          <div className='notif-item-posted-closing'>Closing in 5 days</div>
        </div>
        <MultiTag tagIcon={<Stars />} text={'2'} subtext={'New message(s)'} />
      </div>
      <div className='notif-item-Applicants'>
        <div className='post-job-views'>
          <div className='post-job-views-num'>0</div>
          <div className='post-job-views-label'>Applicants</div>
        </div>
      </div>
      <GButton text={'More'} customClass={'g-button--gray'} />
    </div>
  )
}
