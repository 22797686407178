import React, { useState } from 'react'

import Popup from '../components/Popup'
import Input from '../components/inputs/Input'
import InputFille from '../components/inputs/InputFille'
import GButton from '../components/inputs/GButton'
import CvsItem from '../components/CvsItem'
import { ReactComponent as AddSquare } from '../assets/img/icon/add-square.svg'
import { ReactComponent as Save } from '../assets/img/icon/save-01.svg'

function CvsPage() {
  const [addCvPopup, setAddCvPopup] = useState(false)
  const [addCvSavedPopup, setAddCvSavedPopup] = useState(false)
  const [removeCvPopup, setRemoveCvPopup] = useState(false)

  const handleAddCvOpenPopup = () => {
    setAddCvPopup(true)
    document.body.classList.add('hiden')
  }
  const handleAddCvClosePopup = () => {
    setAddCvPopup(false)
    document.body.classList.remove('hiden')
  }
  const handleAddCvSavedOpenPopup = () => {
    setAddCvSavedPopup(true)
    document.body.classList.add('hiden')
  }
  const handleAddCvSavedClosePopup = () => {
    setAddCvSavedPopup(false)
    document.body.classList.remove('hiden')
  }

  const handleRemoveCvOpenPopup = () => {
    setRemoveCvPopup(true)
    document.body.classList.add('hiden')
  }
  const handleRemoveCvClosePopup = () => {
    setRemoveCvPopup(false)
    document.body.classList.remove('hiden')
  }

  const [uploadCV, setUploadCV] = useState('')
  const handleFile = (file) => {
    setUploadCV(file.name)
  }
  return (
    <>
      <div className='cvs-lists'>
        <CvsItem
          addCv={handleAddCvOpenPopup}
          removeCv={handleRemoveCvOpenPopup}
        />
      </div>
      <button className='full-btn'>
        <AddSquare /> Add a new CV
      </button>
      {addCvPopup && (
        <Popup closePopup={handleAddCvClosePopup}>
          <div className='popup-box-inner'>
            <div className='acc-popup-setting'>
              <Input
                label={'CV Name'}
                customClass={'border'}
                placeholder={'Name your CV'}
              />
              <InputFille
                handleFile={handleFile}
                label={'Upload CV'}
                text={'Drag and drop or upload from computer'}
                customClass={'button-upload--gray'}
              />
              {uploadCV ? <p>Uploaded file: {uploadCV}</p> : null}
              <GButton
                text={'Save'}
                customClass={'g-button--gray '}
                customIcon={<Save />}
              />
            </div>
          </div>
        </Popup>
      )}
      {addCvSavedPopup && (
        <Popup closePopup={handleAddCvSavedClosePopup}>
          <div className='popup-box-inner'>
            <div className='popup-Notification'>
              <div className='popup-Notification-icon'>
                <img
                  src={
                    require(`../assets/img/popup-icon/save-circle.svg`).default
                  }
                  alt=''
                />
              </div>
              <div className='popup-Notification-title'>
              Your CV has been saved!
              </div>
              <button
                onClick={handleRemoveCvClosePopup}
                className='popup-Notification-close'
              >
                Back to CVs
              </button>
            </div>
          </div>
        </Popup>
      )}
      {removeCvPopup && (
        <Popup closePopup={handleRemoveCvClosePopup}>
          <div className='popup-box-inner'>
            <div className='popup-Notification'>
              <div className='popup-Notification-icon'>
                <img
                  src={
                    require(`../assets/img/popup-icon/trash-circle.svg`).default
                  }
                  alt=''
                />
              </div>
              <div className='popup-Notification-title'>
                Your CV has been removed
              </div>
              <button
                onClick={handleRemoveCvClosePopup}
                className='popup-Notification-close'
              >
                Back to CVs
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

export { CvsPage }
