import React from 'react'
import { NavLink } from 'react-router-dom'
import Select from '../../components/inputs/Select'
import JobAdvert from '../../components/JobAdvert'
import { ReactComponent as Refresh } from '../../assets/img/icon/refresh.svg'
import { ReactComponent as SaveIcon } from '../../assets/img/icon/save-01.svg'
import { ReactComponent as Search } from '../../assets/img/icon/search.svg'
import { ReactComponent as Update } from '../../assets/img/icon/update.svg'
import CompanyNotificationsItem from '../../components/CompanyNotificationsItem'
import GButton from '../../components/inputs/GButton'

function CompanyNotificationsPage() {
  const options = [
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
  ]
  const state = [
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
  ]

  const [value, setValue] = React.useState('fruit')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <>
      <div className='filter'>
        <div className='filter-top'>
          <div className='filter-top-left'>
            <div className='filter-top-title'>Filter Notifications</div>
          </div>
        </div>
        <div className='filter-items'>
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <GButton text={'Update Results'} customIcon={<Update />} />
        </div>
      </div>
      <div className='list'>
        {/* {state.map((item, idx) => {
          return (
            <JobAdvert
              key={idx}
              title={item.title}
              address={item.address}
              priceFrom={item.priceFrom}
              priceTo={item.priceTo}
              closingDate={item.closingDate}
              description={item.description}
            />
          )
        })} */}
        <CompanyNotificationsItem />
        <CompanyNotificationsItem />
        <CompanyNotificationsItem />
      </div>
    </>
  )
}

export { CompanyNotificationsPage }
