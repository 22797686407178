import React from 'react'
import SmallButton from './inputs/SmallButton'
import { ReactComponent as Location } from '../assets/img/icon/location.svg'
import { ReactComponent as Stars } from '../assets/img/icon/stars.svg'
import { ReactComponent as Dislike } from '../assets/img/icon/dislike.svg'
import { ReactComponent as Apply } from '../assets/img/icon/apply.svg'
import { ReactComponent as MessageTyping } from '../assets/img/icon/message-typing.svg'
import { ReactComponent as Hend } from '../assets/img/icon/hend.svg'
import MultiTag from './MultiTag'
export default function NotificationsItem() {
  return (
    <div className='notif-item'>
      <div className='notif-item-left'>
        <div className='notif-item-img'>
          <img src={require(`../assets/img/companies/aviva-logo.jpg`)} alt='' />
        </div>
        <div className='notif-item-text'>
          <div className='notif-item-text-title'>Aviva Investors</div>
          <div className='notif-item-text-label'>Message Centre</div>
          <div className='notif-item-text-btn'>
            <SmallButton text={'Waved at you'} customIcon={<Hend />} />
          </div>
        </div>
      </div>
      <div className='notif-item-mid'>
        <div className='notif-item-addrerss'>
          <div className='notif-item-addrerss-title'>
            Account Manager, Remote
          </div>
          <div className='notif-item-addrerss-subtitle'>
            <Location /> Dublin, Ireland
          </div>
        </div>
        <MultiTag tagIcon={<Stars />} text={'2'} subtext={'New message(s)'} />
      </div>
      <div className='notif-item-right'>
        <SmallButton customIcon={<Dislike />} text={'Ignore'} />
        <SmallButton text={'View'} />
        <SmallButton customIcon={<MessageTyping />} text={'Message AMP'} />
        <SmallButton customIcon={<Apply />} text={'Apply'} />
      </div>
    </div>
  )
}
