import React from 'react'

import JobAdvert from '../components/JobAdvert'

function FavouritesPage() {
  const state = [
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
  ]

  return (
    <>
      <div className='list'>
        {state.map((item, idx) => {
          return (
            <JobAdvert
              key={idx}
              title={item.title}
              address={item.address}
              priceFrom={item.priceFrom}
              priceTo={item.priceTo}
              closingDate={item.closingDate}
              description={item.description}
            />
          )
        })}
      </div>
    </>
  )
}

export { FavouritesPage }
