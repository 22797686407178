import React from 'react'
import { NavLink } from 'react-router-dom'
import MultiTag from './MultiTag'
import GButton from './inputs/GButton'
import { ReactComponent as ArrowDownContained } from '../assets/img/icon/arrow-down-contained.svg'
import { ReactComponent as Apply } from '../assets/img/icon/apply.svg'
import { ReactComponent as Edit } from '../assets/img/icon/edit.svg'
import { ReactComponent as BellRinging } from '../assets/img/icon/bell-ringing.svg'
import { ReactComponent as FileAttach } from '../assets/img/icon/file-attach.svg'
import { ReactComponent as Search } from '../assets/img/icon/search.svg'
import TextareaEdit from './inputs/TextareaEdit'
export default function AdminEmployerstItem() {
  return (
    <div className='a-talent-item a-employers-item'>
      <div className='a-talent-item-head'>
        <div className='a-talent-item-text'>
          <div className='a-talent-item-title'>Senior account manager</div>
        </div>
        <div className='post-job-views'>
          <div className='post-job-views-num'>10+</div>
          <div className='post-job-views-label'>Applicants</div>
        </div>
        <div className='a-talent-item-tag'>
          <MultiTag text={'Company'} subtext={'Aviva'} />
        </div>
        <div className='a-employers-item-tag'>
          <div className='a-employers-item-tag-item'>Info 1</div>
          <div className='a-employers-item-tag-item'>Info 2</div>
          <div className='a-employers-item-tag-item'>Info 3</div>
        </div>
        <div className='a-talent-item-btn'>
          <GButton text={'View Talent'} />
          <GButton
            text={'More'}
            customClass={'g-button--gray'}
            customIcon={<ArrowDownContained />}
          />
        </div>
      </div>
      <div className='a-talent-item-dropdown'>
        <div className='a-talent-item-table'>
          <table>
            <tr>
              <th>Notification History</th>
              <th>Talent</th>
              <th>Review</th>
              <th>Shortlisted</th>
            </tr>
            <tr>
              <td>
                <div className='notification-icon'>
                  <BellRinging />
                </div>
                Candidate #1 Applied for this job
              </td>
              <td>
                <div className='t-link-con'>
                  <NavLink>
                    <FileAttach /> View CV
                  </NavLink>
                  <NavLink>
                    <Search /> View Banner
                  </NavLink>
                </div>
              </td>
              <td>Daniel</td>
              <td>
                <div className='t-btn-con'>
                  <div className='t-indecator t-indecator--yes'>Yes</div>
                  <button className='t-btn-edit'>
                    <Edit />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='notification-icon'>
                  <BellRinging />
                </div>
                Candidate #5643 Applied for this job
              </td>
              <td>
                <div className='t-link-con'>
                  <NavLink>
                    <FileAttach /> View CV
                  </NavLink>
                  <NavLink>
                    <Search /> View Banner
                  </NavLink>
                </div>
              </td>
              <td>Michael</td>
              <td>
                <div className='t-btn-con'>
                  <div className='t-indecator t-indecator--no'>Yes</div>
                  <button className='t-btn-edit'>
                    <Edit />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='new-icon'>NEW</div>
                Candidate #5643 Applied for this job
              </td>
              <td>
                <div className='t-link-con'>
                  <NavLink>
                    <FileAttach /> View CV
                  </NavLink>
                  <NavLink>
                    <Search /> View Banner
                  </NavLink>
                </div>
              </td>
              <td></td>
              <td>
                <div className='t-btn-con'>
                  <div className='t-indecator '>Add</div>
                  <button className='t-btn-edit'>
                    <Edit />
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className='a-talent-item-edit'>
          <div className='a-talent-item-edit-textarea'>
            <TextareaEdit
              label={'Notes'}
              value={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis commodo id dui eget ornare. Sed sapien diam, egestas ut augue vel, volutpat tincidunt tortor. Sed lectus nisl, pulvinar sit amet nisl sit amet, vehicula aliquet est. Nullam accumsan risus sit amet tellus imperdiet'
              }
              custom={
                <button className='edit-btn'>
                  <Edit /> Edit
                </button>
              }
            />
            <TextareaEdit
              label={'Notes'}
              value={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis commodo id dui eget ornare. Sed sapien diam, egestas ut augue vel, volutpat tincidunt tortor. Sed lectus nisl, pulvinar sit amet nisl sit amet, vehicula aliquet est. Nullam accumsan risus sit amet tellus imperdiet'
              }
              custom={
                <button className='edit-btn'>
                  <Edit /> Edit
                </button>
              }
            />
          </div>
          <div className='a-talent-item-edit-btns'>
            <div className='a-talent-item-edit-btn'>
              <GButton text={'Edit'} />
              <GButton
                text={'Archive'}
                customClass={'g-button--gray'}
                customIcon={<ArrowDownContained />}
              />
              <GButton text={'Remove'} />
            </div>
            <GButton
              text={'Compile Shortlist'}
              customClass={'g-button--green'}
              customIcon={<Apply />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
