import React from 'react'
import { NavLink } from 'react-router-dom'
import MultiTag from './MultiTag'
import GButton from './inputs/GButton'
import { ReactComponent as ArrowDownContained } from '../assets/img/icon/arrow-down-contained.svg'
import { ReactComponent as Edit } from '../assets/img/icon/edit.svg'
import { ReactComponent as BellRinging } from '../assets/img/icon/bell-ringing.svg'
import TextareaEdit from './inputs/TextareaEdit'
export default function AdminTalentItem() {
  return (
    <div className='a-talent-item'>
      <div className='a-talent-item-head'>
        <div className='a-talent-item-text'>
          <div className='a-talent-item-title'>Johnny Lowe</div>
          <div className='a-talent-item-subtitle'>Candidate 012345</div>
        </div>
        <div className='a-talent-item-tag'>
          <MultiTag text={'Type'} subtext={'Full time (permanent)'} />
          <MultiTag text={'Type'} subtext={'Full time (permanent)'} />
          <MultiTag text={'Type'} subtext={'Full time (permanent)'} />
        </div>
        <div className='a-talent-item-btn'>
          <GButton text={'View Talent'} />
          <GButton
            text={'More'}
            customClass={'g-button--gray'}
            customIcon={<ArrowDownContained />}
          />
        </div>
      </div>
      <div className='a-talent-item-dropdown'>
        <div className='a-talent-item-table'>
          <table>
            <tr>
              <th>Notification History</th>
              <th>Review</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>
                <div className='notification-icon'>
                  <BellRinging />
                </div>
                Candidate #5643 Applied for <NavLink>Account manager, Remote</NavLink>
              </td>
              <td>Daniel</td>
              <td>
                <div className='t-btn-con'>
                  Yes
                  <button className='t-btn-edit'>
                    <Edit /> Edit
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='notification-icon'>
                  <BellRinging />
                </div>
                Aviva Insurance waved at Johnny
              </td>
              <td>Michael</td>
              <td>
                <div className='t-btn-con'>
                  No
                  <button className='t-btn-edit'>
                    <Edit /> Edit
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='new-icon'>NEW</div>
                Candidate #5643 Applied for <NavLink>Account manager, Remote</NavLink>
              </td>
              <td></td>
              <td>
                <button className='t-btn-action'>
                  <Edit />
                  Add action
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div className='a-talent-item-edit'>
          <div className='a-talent-item-edit-textarea'>
            <TextareaEdit
              label={'Notes'}
              value={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis commodo id dui eget ornare. Sed sapien diam, egestas ut augue vel, volutpat tincidunt tortor. Sed lectus nisl, pulvinar sit amet nisl sit amet, vehicula aliquet est. Nullam accumsan risus sit amet tellus imperdiet'
              }
              custom={
                <button className='edit-btn'>
                  <Edit /> Edit
                </button>
              }
            />
            <TextareaEdit
              label={'Notes'}
              value={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis commodo id dui eget ornare. Sed sapien diam, egestas ut augue vel, volutpat tincidunt tortor. Sed lectus nisl, pulvinar sit amet nisl sit amet, vehicula aliquet est. Nullam accumsan risus sit amet tellus imperdiet'
              }
              custom={
                <button className='edit-btn'>
                  <Edit /> Edit
                </button>
              }
            />
          </div>
          <div className='a-talent-item-edit-btns'>
            <div className='a-talent-item-edit-btn'>
              <GButton text={'Edit'} />
              <GButton
                text={'Archive'}
                customClass={'g-button--gray'}
                customIcon={<ArrowDownContained />}
              />
              <GButton text={'Remove'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
