import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Select from '../components/inputs/Select'
import JobAdvert from '../components/JobAdvert'
import { ReactComponent as Refresh } from '../assets/img/icon/refresh.svg'
import { ReactComponent as SaveIcon } from '../assets/img/icon/save-01.svg'
import { ReactComponent as Search } from '../assets/img/icon/search.svg'
import Popup from '../components/Popup'
function HomePage() {
  const options = [
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
  ]
  const state = [
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
    {
      title: 'Senior Pricing Actuary',
      address: 'London, United Kingdom',
      priceFrom: 45000,
      priceTo: 60000,
      closingDate: '01/01/24',
      description:
        'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
    },
  ]

  const [value, setValue] = React.useState('fruit')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const [successPopup, setSuccessPopup] = useState(false)
  const [noCvPopup, setNoCvPopup] = useState(false)
  const [sendMessagePopup, setSendMessagePopup] = useState(false)

  return (
    <>
      <div className='filter'>
        <div className='filter-top'>
          <div className='filter-top-left'>
            <div className='filter-top-title'>Filter Jobs</div>
            <button className='filter-top-reload'>
              <Refresh />
            </button>
            <button className='filter-top-save'>
              <SaveIcon />
            </button>
            <NavLink to='/' className='filter-top-save'>
              34 Jobs match your search
            </NavLink>
          </div>
          <div className='filter-top-right'>
            <div className='filter-top-select'>
              <Select options={options} value={value} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className='filter-items'>
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <Select options={options} value={value} onChange={handleChange} />
          <div className='input-search'>
            <input type='text' placeholder='Search' />
            <Search />
          </div>
        </div>
      </div>
      <div className='list'>
        {state.map((item, idx) => {
          return (
            <JobAdvert
              key={idx}
              title={item.title}
              address={item.address}
              priceFrom={item.priceFrom}
              priceTo={item.priceTo}
              closingDate={item.closingDate}
              description={item.description}
            />
          )
        })}
      </div>
      {successPopup && (
        <Popup>
          <div className='popup-box-inner'>
            <div className='popup-Notification'>
              <div className='popup-Notification-icon'>
                <img
                  src={
                    require(`../assets/img/popup-icon/hand-circle.svg`).default
                  }
                  alt=''
                />
              </div>
              <div className='popup-Notification-title'>
                You have applied for this job
              </div>
              <button className='popup-Notification-close'>Return Home</button>
            </div>
          </div>
        </Popup>
      )}
      {noCvPopup && (
        <Popup>
          <div className='popup-box-inner'>
            <div className='popup-Notification'>
              <div className='popup-Notification-icon'>
                <img
                  src={
                    require(`../assets/img/popup-icon/x-circle.svg`).default
                  }
                  alt=''
                />
              </div>
              <div className='popup-Notification-title'>
                You must have a CV uploaded before applying for a job
              </div>
              <NavLink>Go to my CVs</NavLink>
              <button className='popup-Notification-close'>Back</button>
            </div>
          </div>
        </Popup>
      )}
      {sendMessagePopup && (
        <Popup>
          <div className='popup-box-inner'>
            <div className='popup-Notification'>
              <div className='popup-Notification-icon'>
                <img
                  src={
                    require(`../assets/img/popup-icon/send-circle.svg`).default
                  }
                  alt=''
                />
              </div>
              <div className='popup-Notification-title'>
              Your message has been sent
              </div>
              <NavLink>Go to messages</NavLink>
              <button className='popup-Notification-close'>Return Home</button>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

export { HomePage }
