import React from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as Edit } from '../../assets/img/icon/edit.svg'
import { ReactComponent as ArrowRight } from '../../assets/img/icon/arrow-right.svg'
import SmallButton from '../../components/inputs/SmallButton'

function SettingsPage() {
  return (
    <>
      <div className='interface-panel-list'>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Personal Profile</div>
            <div className='interface-panel-description'>
              Main details and your summary CV
            </div>
          </div>
          <div className='interface-panel-right'>
            <Edit />
          </div>
        </div>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Notification Settings</div>
            <div className='interface-panel-description'>
              When you are notified by AMP
            </div>
          </div>
          <div className='interface-panel-right'>
            <NavLink to='/settings/notification'>
              <Edit />
            </NavLink>
          </div>
        </div>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Account Settings</div>
            <div className='interface-panel-description'>
              Email and Password settings
            </div>
          </div>
          <div className='interface-panel-right'>
            <NavLink to='/settings/account'>
              <Edit />
            </NavLink>
          </div>
        </div>
        <div className='interface-panel logout'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Logout</div>
          </div>
          <div className='interface-panel-right'>
            
            <SmallButton customIcon={ <ArrowRight />}  text={'Logout'}/>
          </div>
        </div>
      </div>
    </>
  )
}

export { SettingsPage }
