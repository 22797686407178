import React from 'react'

function NotfoundPage() {
  return (

      <div>NotfoundPage</div>

  )
}

export { NotfoundPage }
