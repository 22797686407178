import React from 'react'

export default function Input({
  type = 'text',
  placeholder= '',
  label,
  customClass = '',
  custom = null,
}) {
  return (
    <div className={`c-input ${customClass ? customClass : ''}`}>
      <label>{label}</label>
      <div className='c-input-con'>
        <input type={type} placeholder={placeholder}/>
        {custom}
      </div>
    </div>
  )
}
