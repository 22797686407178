import React from 'react'

export default function Select({value, options, onChange }) {
  return (
    <select className='g-select' value={value} onChange={onChange}>
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
}
