import React from 'react'

export default function SwitchCheckbox() {
  return (
    <label className='switch'>
      <input type='checkbox' defaultChecked />
      <span className='slider round'></span>
    </label>
  )
}
