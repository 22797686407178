import React from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as Home } from '../assets/img/asite/home.svg'
import { ReactComponent as Profile } from '../assets/img/asite/profile.svg'
import { ReactComponent as Save } from '../assets/img/asite/save.svg'
import { ReactComponent as FileAttach } from '../assets/img/asite/file-attach.svg'
import { ReactComponent as Heart } from '../assets/img/asite/heart.svg'
import { ReactComponent as Inbox } from '../assets/img/asite/inbox.svg'
import { ReactComponent as Chat } from '../assets/img/asite/chat.svg'
import { ReactComponent as Jobs } from '../assets/img/asite/jobs.svg'
import { ReactComponent as Send } from '../assets/img/asite/send.svg'

function Aside({ asideClass }) {
  return (
    <>
      <aside className={`aside ${asideClass ? 'open' : ''}`}>
        <div className='aside__inner'>
          <nav className='aside__inner-nav'>
            <ul>
              <li>
                <NavLink to='/'>
                  <Home />
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to='/jobs'>
                  <Jobs />
                  Jobs
                </NavLink>
              </li>
              <li>
                <NavLink to='/notifications-comp'>
                  <Send />
                  Jobs
                </NavLink>
              </li>
              <li>
                <NavLink to='/profile'>
                  <Profile />
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink to='/talent'>
                  <Profile />
                  Talent
                </NavLink>
              </li>
              <li>
                <NavLink to='/saved-searches'>
                  <Save />
                  Saved Searches
                </NavLink>
              </li>
              <li>
                <NavLink to='/cvs'>
                  <FileAttach />
                  CVs
                </NavLink>
              </li>
              <li>
                <NavLink to='/favourites'>
                  <Heart />
                  Favourites
                </NavLink>
              </li>
              <li>
                <NavLink to='/ds'>
                  <Inbox />
                  Inbox
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className='aside__inner-chat'>
            <NavLink to='/chat'>
              <Chat />
              Messages
            </NavLink>
          </div>
        </div>
      </aside>
      <div className={`aside-bg ${asideClass ? 'open' : ''}`}></div>
    </>
  )
}

export { Aside }
