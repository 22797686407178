import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import { Aside } from './Aside'

function Layout() {
  const [asideClass, setAsideClass] = useState(false)
  const handleAsideClass = (classAsite) => {
    setAsideClass(classAsite)
  }
 
  return (
    /**
     * Markup to render only the content of pages, and elements that are repeated on each page are loaded only once
     *
     * This markup is also used to attach a footer to the bottom
     */
    <div className='wrapper'>
      <Header changeClass={handleAsideClass} />
      <Aside asideClass={asideClass} />
      <main className='main'>
        <div className='container'>
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export { Layout }
