import React from 'react'
import { ReactComponent as Marker } from '../assets/img/multi-tag/marker.svg'

export default function MultiTag({ tagIcon = null, text, subtext }) {
  return (
    <div className='multi-tag'>
      <div className='multi-tag-icon'>{tagIcon ? tagIcon : <Marker />}</div>
      <div className='multi-tag-text'>
        <span>{text}:</span> {subtext}
      </div>
    </div>
  )
}
