import React from 'react'
import Input from '../components/inputs/Input'
import GButton from '../components/inputs/GButton'
import { ReactComponent as Edit } from '../assets/img/icon/edit.svg'
import Textarea from '../components/inputs/Textarea'

function ProfilePage() {
  return (
    <>
      <form className='profile-form'>
        <div className='profile-form-con'>
          <div className='profile-form-con-box'>
            <Input
              label={'First Name'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Last'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Phone Number'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Email Address'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'First Name'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Qualification (Highest)'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Years Experience'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Year of Qualification'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
          </div>
          <Input
            label={'Company Name'}
            custom={
              <button className='edit-btn'>
                <Edit />
              </button>
            }
          />
          <div className='profile-form-con-box'>
            <Input
              label={'Seeking Status'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Job Type'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Current Job title'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Open to'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Remote/WFH'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Work Type'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Sector'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Job Type'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
          </div>
          <Textarea
            label={'Outside office interests'}
            custom={
              <button className='edit-btn'>
                <Edit />
              </button>
            }
          />
        </div>
        <div className='profile-form-line' />
        <div className='profile-form-con'>
          <div className='profile-form-con-title'>Open to</div>
          <div className='profile-form-con-box label'>
            <Input
              label={'Location'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Level of remoteness'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Type'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Office'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Sector'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
          </div>
          <GButton text={'Preview'} />
        </div>
      </form>
    </>
  )
}

export { ProfilePage }
