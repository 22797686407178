import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg'
import Input from '../../components/inputs/Input'
import GButton from '../../components/inputs/GButton'
import { ReactComponent as Edit } from '../../assets/img/icon/edit.svg'
import { ReactComponent as Link } from '../../assets/img/icon/link.svg'
import Textarea from '../../components/inputs/Textarea'
import InputFille from '../../components/inputs/InputFille'

function CompanySettingsPage() {
  const [compLogo, setCompLogo] = useState('')
  const handleFile = (file) => {
    setCompLogo(file.name)
  }

  const [newAdmin, setNewAdmin] = useState('')
  const handleNewAdmin = (file) => {
    setNewAdmin(file.name)
  }
  return (
    <>
      <NavLink className='link-back' to={'/settings'}>
        <ArrowLeft />
        Back to all settings
      </NavLink>
      <div className='profile-form-con-box col-1'>
        <Input label={'Company Name'} customClass={'border'} />
        <Input label={'Location'} customClass={'border'} />
        <InputFille handleFile={handleFile} label={'Company Logo'} text={'Upload from computer'}/>
        {compLogo ? <p>Uploaded file: {compLogo}</p> : null}
        <InputFille handleFile={handleNewAdmin} label={'Add new admin'} text={'Add a new admin'} />
        {newAdmin ? <p>Uploaded file: {newAdmin}</p> : null}
        
      </div>
    </>
  )
}

export { CompanySettingsPage }
