import React from 'react'
import GButton from './inputs/GButton'
import { ReactComponent as MessageTyping } from '../assets/img/icon/message-typing.svg'
import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as GraduationCap } from '../assets/img/multi-tag/graduation-cap.svg'
import { ReactComponent as Luggage } from '../assets/img/multi-tag/luggage.svg'
import MultiTag from './MultiTag'
export default function PostJobItem() {
  return (
    <div className='post-job'>
      <div className='post-job-head'>
        <div className='post-job-title'>Account Manager, Remote</div>
        <div className='post-job-views'>
          <div className='post-job-views-num'>0</div>
          <div className='post-job-views-label'>Views</div>
        </div>
        <div className='post-job-btn'>
          <GButton text={'View'} />
          <GButton text={'Message AMP'} customIcon={<MessageTyping />} />
        </div>
      </div>
      <div className='post-job-tag'>
        <MultiTag
          tagIcon={<ClockForward />}
          text={'Type'}
          subtext={'Full time (permanent)'}
        />
        <MultiTag
          tagIcon={<GraduationCap />}
          text={'Qualification'}
          subtext={'Bachelors Degree'}
        />
        <MultiTag text={'Location'} subtext={'Hybrid'} />
        <MultiTag
          tagIcon={<Luggage />}
          text={'Sector'}
          subtext={'Accounting'}
        />
      </div>
    </div>
  )
}
