import React from 'react'

export default function Textarea({ label, custom = null,  }) {
  return (
    <div className='c-textarea'>
      <label>{label}</label>
      <div className='c-textarea-con'>
        <textarea />
        {custom}
      </div>
    </div>
  )
}
