import React from 'react'

import { ReactComponent as Location } from '../assets/img/icon/location.svg'
import { ReactComponent as Funt } from '../assets/img/icon/funt.svg'
import { ReactComponent as ClosingDate } from '../assets/img/icon/closing-date.svg'
import { ReactComponent as Favorite } from '../assets/img/icon/favorite.svg'
import { ReactComponent as ApplyIcon } from '../assets/img/icon/apply-icon.svg'

import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as GraduationCap } from '../assets/img/multi-tag/graduation-cap.svg'
import { ReactComponent as Luggage } from '../assets/img/multi-tag/luggage.svg'

import Ifrs from '../assets/img/skills-icon/ifrs.png'
import Resq from '../assets/img/skills-icon/resq.png'
import Python from '../assets/img/skills-icon/python.png'
import Skill01 from '../assets/img/skills-icon/skill-01.png'
import Excel from '../assets/img/skills-icon/excel.png'
import Access from '../assets/img/skills-icon/access.png'
import Sas from '../assets/img/skills-icon/sas.png'
import Skill02 from '../assets/img/skills-icon/skill-02.png'
import Gaap from '../assets/img/skills-icon/gaap.png'
import GButton from './inputs/GButton'
import DButton from './inputs/DButton'
import MultiTag from './MultiTag'

export default function JobAdvert({
  title,
  address,
  priceFrom,
  priceTo,
  closingDate,
  description,
}) {
  return (
    <div className='job-advert'>
      <div className='job-advert-left'>
        <div className='job-advert-title'>{title}</div>
        <div className='job-advert-list'>
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Type'}
            subtext={'Full time (permanent)'}
          />
          <MultiTag
            tagIcon={<GraduationCap />}
            text={'Qualification'}
            subtext={'Bachelors Degree'}
          />
          <MultiTag text={'Location'} subtext={'Hybrid'} />
          <MultiTag
            tagIcon={<Luggage />}
            text={'Sector'}
            subtext={'Accounting'}
          />
        </div>
      </div>
      <div className='job-advert-right'>
        <div className='job-advert-con'>
          <div className='job-advert-skills'>
            <div className='job-advert-skills-item'>
              <div className='job-advert-skills-item-title'>
                Seeking Proficiency in:
              </div>
              <div className='job-advert-skills-item-list'>
                <img src={Ifrs} alt='' />
                <img src={Resq} alt='' />
                <img src={Python} alt='' />
                <img src={Skill01} alt='' />
                <img src={Excel} alt='' />
                <img src={Access} alt='' />
                <img src={Sas} alt='' />
                <img src={Skill02} alt='' />
                <img src={Gaap} alt='' />
              </div>
            </div>
            <div className='job-advert-skills-item'>
              <div className='job-advert-skills-item-title'>
                Working knowledge in:
              </div>
              <div className='job-advert-skills-item-list'>
                <img src={Ifrs} alt='' />
                <img src={Resq} alt='' />
                <img src={Python} alt='' />
                <img src={Skill01} alt='' />
                <img src={Excel} alt='' />
                <img src={Access} alt='' />
                <img src={Sas} alt='' />
                <img src={Skill02} alt='' />
                <img src={Gaap} alt='' />
              </div>
            </div>
          </div>
          <div className='job-advert-info'>
            <div className='job-advert-info-item'>
              <div className='job-advert-info-item-icon'>
                <Location />
              </div>
              <div className='job-advert-info-item-text'>{address}</div>
            </div>
            <div className='job-advert-info-item'>
              <div className='job-advert-info-item-icon'>
                <Funt />
              </div>
              <div className='job-advert-info-item-text'>
                £{priceFrom} - £{priceTo}
              </div>
            </div>
            <div className='job-advert-info-item'>
              <div className='job-advert-info-item-icon'>
                <ClosingDate />
              </div>
              <div className='job-advert-info-item-label'>Closing Date:</div>
              <div className='job-advert-info-item-text'>{closingDate}</div>
            </div>
          </div>
          <div className='job-advert-description'>
            <p>{description}</p>
          </div>
          <div className='job-advert-btns'>
            <DButton text={'Message AMP'} />
            <GButton text={'Apply'} customIcon={<ApplyIcon />} />
            <DButton text={'View More'} />
          </div>
        </div>
      </div>
      <div className='job-advert-favorite'>
        <button>
          <Favorite />
        </button>
      </div>
    </div>
  )
}
