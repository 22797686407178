import React from 'react'
import Input from '../../components/inputs/Input'
import GButton from '../../components/inputs/GButton'
import { ReactComponent as Edit } from '../../assets/img/icon/edit.svg'
import { ReactComponent as Link } from '../../assets/img/icon/link.svg'
import Textarea from '../../components/inputs/Textarea'

function AddUsersPage() {
  return (
    <>
      <form className='profile-form'>
        <div className='profile-form-con'>
          <div className='profile-form-con-box'>
            <Input
              label={'First Name'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Last'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Phone Number'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
            <Input
              label={'Email Address'}
              custom={
                <button className='edit-btn'>
                  <Edit />
                </button>
              }
            />
          </div>
          <GButton text={'Send Invite'} customIcon={<Link />} />
        </div>
      </form>
    </>
  )
}

export { AddUsersPage }
