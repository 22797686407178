import React from 'react'
import { ReactComponent as SearchWhite } from '../../assets/img/icon/search-white.svg'

export default function SmallButton({ type = 'button', text, customIcon = null }) {
  return (
    <button type={type} className='small-button'>
      {customIcon ? customIcon : <SearchWhite />}

      {text}
    </button>
  )
}
