import React from 'react'
import { ReactComponent as XCircle } from '../assets/img/icon/x-circle.svg'

export default function Popup({ children, closePopup }) {
  return (
    <div className='popup' onClick={closePopup}>
      <button className='popup-close' type='button' onClick={closePopup}>
        <XCircle /> Close
      </button>
      <div className='popup-bg' />
      <div className='popup-inner'>
        <div
          className='popup-box'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
