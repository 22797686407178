import React from 'react'

import { ReactComponent as Location } from '../assets/img/icon/location.svg'
import { ReactComponent as Funt } from '../assets/img/icon/funt.svg'
import { ReactComponent as ClosingDate } from '../assets/img/icon/closing-date.svg'
import { ReactComponent as Favorite } from '../assets/img/icon/favorite.svg'
import { ReactComponent as ApplyIcon } from '../assets/img/icon/apply-icon.svg'
import { ReactComponent as LightStatus } from '../assets/img/icon/light-status.svg'
import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as GraduationCap } from '../assets/img/multi-tag/graduation-cap.svg'
import { ReactComponent as Luggage } from '../assets/img/multi-tag/luggage.svg'
import { ReactComponent as Dislike } from '../assets/img/icon/dislike.svg'

import Ifrs from '../assets/img/skills-icon/ifrs.png'
import Resq from '../assets/img/skills-icon/resq.png'
import Python from '../assets/img/skills-icon/python.png'
import Skill01 from '../assets/img/skills-icon/skill-01.png'
import Excel from '../assets/img/skills-icon/excel.png'
import Access from '../assets/img/skills-icon/access.png'
import Sas from '../assets/img/skills-icon/sas.png'
import Skill02 from '../assets/img/skills-icon/skill-02.png'
import Gaap from '../assets/img/skills-icon/gaap.png'
import GButton from './inputs/GButton'
import SmallButton from './inputs/SmallButton'
import MultiTag from './MultiTag'

export default function TalentItem({}) {
  return (
    <div className='job-advert talent-item'>
      <div className='job-advert-left'>
        <div className='job-advert-img'>
          <img src={require(`../assets/img/talent/talent-1.jpg`)} alt='' />
        </div>
        <div className='job-advert-list'>
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Type'}
            subtext={'Full time (permanent)'}
          />
          <MultiTag
            tagIcon={<GraduationCap />}
            text={'Qualification'}
            subtext={'Bachelors Degree'}
          />
          <MultiTag text={'Location'} subtext={'Hybrid'} />
          <MultiTag
            tagIcon={<Luggage />}
            text={'Sector'}
            subtext={'Accounting'}
          />
        </div>
        <SmallButton text={'123'} customIcon={<Dislike />} />
      </div>
      <div className='job-advert-right'>
        <div className='job-advert-con'>
          <div className='job-advert-skills'>
            <div className='job-advert-skills-item'>
              <div className='job-advert-skills-item-title'>
                Seeking Proficiency in:
              </div>
              <div className='job-advert-skills-item-list'>
                <img src={Ifrs} alt='' />
                <img src={Resq} alt='' />
                <img src={Python} alt='' />
                <img src={Skill01} alt='' />
                <img src={Excel} alt='' />
                <img src={Access} alt='' />
                <img src={Sas} alt='' />
                <img src={Skill02} alt='' />
                <img src={Gaap} alt='' />
              </div>
            </div>
            <div className='job-advert-skills-item'>
              <div className='job-advert-skills-item-title'>
                Working knowledge in:
              </div>
              <div className='job-advert-skills-item-list'>
                <img src={Ifrs} alt='' />
                <img src={Resq} alt='' />
                <img src={Python} alt='' />
                <img src={Skill01} alt='' />
                <img src={Excel} alt='' />
                <img src={Access} alt='' />
                <img src={Sas} alt='' />
                <img src={Skill02} alt='' />
                <img src={Gaap} alt='' />
              </div>
            </div>
          </div>
          <div className='job-advert-box'>
            <div className='job-advert-description-con'>
              <div className='job-advert-description'>
                <div className='job-advert-description-title'>Condensed CV</div>
                <p>
                  Seasoned Actuary with eight years of professional experience,
                  specializing in life insurance and pension fund management.
                  Holding an MSc in Actuarial Science from the University of
                  XYZ, Developed and implemented robust financial and actuarial
                  models that have significantly improved risk assessment and
                  financial planning strategies at his emplover. Lead a team of
                  two...
                </p>
              </div>
              <div className='job-advert-description'>
                <div className='job-advert-description-title'>
                  Outside the office
                </div>
                <p>
                  Avid alpine skier and a wine enthusiast. Actively participate
                  in community services, serving as a treasurer for a local
                  non-profit dedicated to environmental conservation. Enjoys
                  traveling with family, exploring different cultures and
                  experimenting with...
                </p>
              </div>
            </div>
            <div className='job-advert-box-panel'>
              <div className='job-advert-box-panel-title'>Open to</div>
              <div className='job-advert-box-panel-items'>
                <div className='job-advert-box-panel-item'>
                  <div className='job-advert-box-panel-item-text'>Location</div>
                  <div className='job-advert-box-panel-item-title'>
                    Dublin, Belfast
                  </div>
                </div>
                <div className='job-advert-box-panel-item'>
                  <div className='job-advert-box-panel-item-text'>
                    Level of Remoteness
                  </div>
                  <div className='job-advert-box-panel-item-title'>Hybrid</div>
                </div>
                <div className='job-advert-box-panel-item'>
                  <div className='job-advert-box-panel-item-text'>Type</div>
                  <div className='job-advert-box-panel-item-title'>
                    Full Time
                  </div>
                </div>
                <div className='job-advert-box-panel-item'>
                  <div className='job-advert-box-panel-item-text'>Office</div>
                  <div className='job-advert-box-panel-item-title'>
                    Dublin, Belfast
                  </div>
                </div>
                <div className='job-advert-box-panel-item'>
                  <div className='job-advert-box-panel-item-text'>Sector</div>
                  <div className='job-advert-box-panel-item-title'>
                    Finance, Banking
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='job-advert-panel'>
        <div className='job-advert-panel-status'>
          <LightStatus />
          <LightStatus />
          <LightStatus />
        </div>
        <div className='job-advert-panel-text'>You waved at this talent</div>
        <GButton text={'View'} />
      </div>
    </div>
  )
}
