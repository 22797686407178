import React from 'react'
import MultiTag from '../components/MultiTag'
import GButton from '../components/inputs/GButton'
import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as Trash } from '../assets/img/icon/trash.svg'
import { ReactComponent as Edit } from '../assets/img/icon/edit.svg'
export default function CvsItem({ addCv, removeCv }) {
  return (
    <div className='cvs-item'>
      <div className='cvs-item-title'>My search name</div>
      <div className='cvs-item-con'>
        <div className='cvs-item-tag'>
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Type'}
            subtext={'Full time (permanent)'}
          />
          <button type='button' onClick={addCv}>
            <Edit />
          </button>
          <button type='button' onClick={removeCv}>
            <Trash />
          </button>
        </div>
        <div className='cvs-item-btn'>
          <GButton text={'View'} />
        </div>
      </div>
    </div>
  )
}
