import React from 'react'
import { ReactComponent as SearchWhite } from '../../assets/img/icon/search-white.svg'

export default function GButton({
  type = 'button',
  text,
  customIcon = null,
  customClass = '',
}) {
  return (
    <button
      type={type}
      className={`g-button ${customClass ? customClass : ''}`}
    >
      {customIcon ? customIcon : <SearchWhite />}

      {text}
    </button>
  )
}
