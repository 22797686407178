import React, { useState, useRef } from 'react'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { NavLink } from 'react-router-dom'

/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */

import { ReactComponent as Logo } from '../assets/img/logo.svg'
import { ReactComponent as LogoMobile } from '../assets/img/logo-mobile.svg'
import { ReactComponent as Notification } from '../assets/img/notification.svg'
import { ReactComponent as Settings } from '../assets/img/settings.svg'

function Header({changeClass}) {
  const [isActiveDropdown, setActiveDropdown] = useState(false)
  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)

  /**
   * Switch for drop down
   */
  const handleToggleDropdown = () => {
    setActiveDropdown(!isActiveDropdown)
  }

  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
    changeClass(!isActiveMenuBtn)
  }

  /**
   * The function of closing elements when clicking on outside the element
   */
  const tooltipRef = useRef(null)
  const onCloseOutside = () => {
    setActiveDropdown(false)
  }
  useOutsideClick(tooltipRef, onCloseOutside, isActiveDropdown)

  const [windowWidth, setWindowWidth] = useState(window.screen.width)

  React.useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.screen.width)
    }
    // Ваш код
    return () => {
      window.onresize = false
    }
  }, [windowWidth])

  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div
            className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
            onClick={handleToggleMenuBtn}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {windowWidth <= 768 ? null : (
            <div className='header__inner-welcome'>
              Welcome back <NavLink to='/'>Johnny</NavLink>
            </div>
          )}

          <div className='header__inner-logo'>
            <NavLink to='/'>
              {windowWidth <= 768 ? <LogoMobile /> : <Logo />}
            </NavLink>
          </div>
          <div className='header__inner-link'>
            <NavLink to='/notifications'>
              <Notification />
            </NavLink>
            <NavLink to='/settings'>
              <Settings />
            </NavLink>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
