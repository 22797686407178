import { Routes, Route } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { ProfilePage } from './pages/ProfilePage'
import { SavedSearchesPage } from './pages/SavedSearchesPage'
import { CvsPage } from './pages/CvsPage'
import { FavouritesPage } from './pages/FavouritesPage'
import { SettingsPage } from './pages/settings/SettingsPage'
import { AccountSettingsPage } from './pages/settings/AccountSettingsPage'
import { NotificationSettingsPage } from './pages/settings/NotificationSettingsPage'
import { NotificationsPage } from './pages/NotificationsPage'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

import { CompanyHomePage } from './pages/company/CompanyHomePage'
import { CompanyNotificationsPage } from './pages/company/CompanyNotificationsPage'
import { JobsPage } from './pages/JobsPage'
import { TalentPage } from './pages/TalentPage'
import { CompanyNotificationSettingsPage } from './pages/settings/CompanyNotificationSettingsPage'
import { AddUsersPage } from './pages/settings/AddUsersPage'
import { CompanySettingsPage } from './pages/settings/CompanySettingsPage'

import { AdminTalentPage } from './pages/admin/AdminTalentPage'
import { AdminEmployersPage } from './pages/admin/AdminEmployersPage'
import { AdminNotificationsPage } from './pages/admin/AdminNotificationsPage'
import { AdminNewTalentPage } from './pages/admin/AdminNewTalentPage'
import { AdminTalentSettings } from './pages/settings/AdminTalentSettings'
import { AdminEmployerSettings } from './pages/settings/AdminEmployerSettings'
import { AdminUsersSettings } from './pages/settings/AdminUsersSettings'

/**
 * In this project, I decided to use scss, so that I could easily type it 
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='saved-searches' element={<SavedSearchesPage />} />
          <Route path='cvs' element={<CvsPage />} />
          <Route path='favourites' element={<FavouritesPage />} />
          <Route path='settings' element={<SettingsPage />} />
          <Route path='settings/account' element={<AccountSettingsPage />} />
          <Route path='settings/notification' element={<NotificationSettingsPage />} />
          <Route path='notifications' element={<NotificationsPage />} />
          <Route path='*' element={<NotfoundPage />} />


          <Route path='home-company' element={<CompanyHomePage />} />
          <Route path='jobs' element={<JobsPage />} />
          <Route path='notifications-comp' element={<CompanyNotificationsPage />} />
          <Route path='talent' element={<TalentPage />} />
          <Route path='settings/notification-comp' element={<CompanyNotificationSettingsPage />} />
          <Route path='settings/add-users' element={<AddUsersPage />} />
          <Route path='settings/company-settings' element={<CompanySettingsPage />} />


          <Route path='admin-talent' element={<AdminTalentPage />} />
          <Route path='admin-employers' element={<AdminEmployersPage />} />
          <Route path='admin-notifications' element={<AdminNotificationsPage />} />
          <Route path='admin-new-talent' element={<AdminNewTalentPage />} />
          <Route path='admin-talent-settings' element={<AdminTalentSettings />} />
          <Route path='admin-employer-settings' element={<AdminEmployerSettings />} />
          <Route path='admin-users-settings' element={<AdminUsersSettings />} />

        </Route>
      </Routes>
    </>
  )
}

export default App
