import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg'
import SwitchCheckbox from '../../components/inputs/SwitchCheckbox'
import Select from '../../components/inputs/Select'

function NotificationSettingsPage() {
  const options = [
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
    { label: 'Type', value: 'type' },
  ]
  const [value, setValue] = React.useState('fruit')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <>
      <NavLink className='link-back' to={'/settings'}>
        <ArrowLeft />
        Back to all settings
      </NavLink>
      <div className='interface-panel-list'>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Notification frequency</div>
            <div className='interface-panel-description'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac
              sagittis ante. Donec sit amet ligula vitae ante interdum euismod.
              Ut vitae ex a augue luctus pellentesque vitae a nibh. Aenean ante
              augue, finibus ut egestas ac, posuere sit amet felis. Mauris eget
              neque ornare, sollicitudin orci et, suscipit dui.
            </div>
          </div>
          <div className='interface-panel-right'>
            <div className='interface-panel-select'>
              <Select options={options} value={value} onChange={handleChange} />
              <SwitchCheckbox />
            </div>
          </div>
        </div>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>
              Email when someone waves at me
            </div>
            <div className='interface-panel-description'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac
              sagittis ante. Donec sit amet ligula vitae ante interdum euismod.
              Ut vitae ex a augue luctus pellentesque vitae a nibh. Aenean ante
              augue, finibus ut egestas ac, posuere sit amet felis. Mauris eget
              neque ornare, sollicitudin orci et, suscipit dui.
            </div>
          </div>
          <div className='interface-panel-right'>
            <SwitchCheckbox />
          </div>
        </div>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>
              Notify me before a favourite job is about to end
            </div>
            <div className='interface-panel-description'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac
              sagittis ante. Donec sit amet ligula vitae ante interdum euismod.
              Ut vitae ex a augue luctus pellentesque vitae a nibh. Aenean ante
              augue, finibus ut egestas ac, posuere sit amet felis. Mauris eget
              neque ornare, sollicitudin orci et, suscipit dui.
            </div>
          </div>
          <div className='interface-panel-right'>
            <SwitchCheckbox />
          </div>
        </div>
      </div>
    </>
  )
}

export { NotificationSettingsPage }
