import React from 'react'
import MultiTag from '../components/MultiTag'
import GButton from '../components/inputs/GButton'
import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as Trash } from '../assets/img/icon/trash.svg'
export default function SearchNameItem() {
  return (
    <div className='search-name'>
      <div className='search-name-title'>My search name</div>
      <div className='search-name-con'>
        <div className='search-name-tags'>
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Type'}
            subtext={'Full time (permanent)'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Location'}
            subtext={'Belfast, UK'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Sector'}
            subtext={'Accounting'}
          />
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Qualification level'}
            subtext={'Bachelors Degree'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Remote/hybrid'}
            subtext={'Remote'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Job Type'}
            subtext={'Part Time'}
          />
          <MultiTag
            tagIcon={<ClockForward />}
            text={'Keywords'}
            subtext={'Senior, Remote'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Salary'}
            subtext={'£67,000+'}
          />
           <MultiTag
            tagIcon={<ClockForward />}
            text={'Pro rata avability'}
            subtext={'-'}
          />
        </div>
        <div className='search-name-btn'>
          <GButton text={'View'} />
          <button type='button'>
            <Trash />
          </button>
        </div>
      </div>
    </div>
  )
}
