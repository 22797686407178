import React from 'react'

import SwitchCheckbox from '../components/inputs/SwitchCheckbox'
import SearchNameItem from '../components/SearcNameItem'

function SavedSearchesPage() {
  return (
    <>
      <div className='interface-panel'>
        <div className='interface-panel-left'>
          <div className='interface-panel-title'>
            Notify me weekly via email with my new results in saved searched
          </div>
          <div className='interface-panel-description'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac
            sagittis ante. Donec sit amet ligula vitae ante interdum euismod. Ut
            vitae ex a augue luctus pellentesque vitae a nibh. Aenean ante
            augue, finibus ut egestas ac, posuere sit amet felis. Mauris eget
            neque ornare, sollicitudin orci et, suscipit dui.
          </div>
        </div>
        <div className='interface-panel-right'>
          <SwitchCheckbox />
        </div>
      </div>
      <div className='search-name-lists'>
        <SearchNameItem />
      </div>
    </>
  )
}

export { SavedSearchesPage }
