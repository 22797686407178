import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as Edit } from '../../assets/img/icon/edit.svg'
import { ReactComponent as Save } from '../../assets/img/icon/save-01.svg'
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg'
import Popup from '../../components/Popup'
import Input from '../../components/inputs/Input'
import GButton from '../../components/inputs/GButton'

function AccountSettingsPage() {
  const [emailChangePopup, setEmailChangePopup] = useState(false)
  const [passChangePopup, setPassChangePopup] = useState(false)

  const handleEmailChangeOpenPopup = () => {
    setEmailChangePopup(true)
    document.body.classList.add('hiden')
  }
  const handleEmailChangeClosePopup = () => {
    setEmailChangePopup(false)
    document.body.classList.remove('hiden')
  }
  const handlePassChangeOpenPopup = () => {
    setPassChangePopup(true)
    document.body.classList.add('hiden')
  }
  const handlePassChangeClosePopup = () => {
    setPassChangePopup(false)
    document.body.classList.remove('hiden')
  }

  return (
    <>
      <NavLink className='link-back' to={'/settings'}>
        <ArrowLeft />
        Back to all settings
      </NavLink>
      <div className='interface-panel-list'>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Email</div>
            <div className='interface-panel-description'>
              hi@johnnylowe.design
            </div>
          </div>
          <div className='interface-panel-right'>
            <button
              onClick={() => {
                handleEmailChangeOpenPopup()
              }}
            >
              <Edit />
            </button>
          </div>
        </div>
        <div className='interface-panel'>
          <div className='interface-panel-left'>
            <div className='interface-panel-title'>Password</div>
            <div className='interface-panel-description'>*****************</div>
          </div>
          <div className='interface-panel-right'>
            <div className='interface-panel-right'>
              <button
                onClick={() => {
                  handlePassChangeOpenPopup()
                }}
              >
                <Edit />
              </button>
            </div>
          </div>
        </div>
      </div>
      {emailChangePopup && (
        <Popup closePopup={handleEmailChangeClosePopup}>
          <div className='popup-box-inner'>
            <div className='acc-popup-setting'>
              <Input
                label={'New Email'}
                customClass={'border'}
                placeholder={'Email Address'}
              />
              <Input
                label={'Confirm New Email'}
                customClass={'border'}
                placeholder={'Email Address'}
              />
              <GButton
                text={'Save'}
                customClass={'g-button--gray '}
                customIcon={<Save />}
              />
            </div>
          </div>
        </Popup>
      )}
      {passChangePopup && (
        <Popup closePopup={handlePassChangeClosePopup}>
          <div className='popup-box-inner'>
            <div className='acc-popup-setting'>
              <Input
                label={'New Password'}
                customClass={'border'}
                placeholder={'New Password'}
              />
              <Input
                label={' Confirm New Password'}
                customClass={'border'}
                placeholder={'New Password'}
              />
              <GButton
                text={'Save'}
                customClass={'g-button--gray '}
                customIcon={<Save />}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

export { AccountSettingsPage }
