import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as AddSquare } from '../assets/img/icon/add-square.svg'
import PostLiveJobItem from '../components/PostLiveJobItem'

function JobsPage() {
  // const state = [
  //   {
  //     title: 'Senior Pricing Actuary',
  //     address: 'London, United Kingdom',
  //     priceFrom: 45000,
  //     priceTo: 60000,
  //     closingDate: '01/01/24',
  //     description:
  //       'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
  //   },
  //   {
  //     title: 'Senior Pricing Actuary',
  //     address: 'London, United Kingdom',
  //     priceFrom: 45000,
  //     priceTo: 60000,
  //     closingDate: '01/01/24',
  //     description:
  //       'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
  //   },
  //   {
  //     title: 'Senior Pricing Actuary',
  //     address: 'London, United Kingdom',
  //     priceFrom: 45000,
  //     priceTo: 60000,
  //     closingDate: '01/01/24',
  //     description:
  //       'The Pricing Actuary will develop, analyse, and set competitive pricing strategies for insurance products. Responsibilities include modelling risk, assessing profitability, performing market analysis, and ensuring compliance with regulatory standards. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools based on market dynamics is essential. The role demands strong analytical skills, expertise in actuarial science, and proficiency in data analysis tools',
  //   },
  // ]
  return (
    <>
      <button className='full-btn full-btn--green'>
        <AddSquare /> Post new job
      </button>
      <div className='tab mt-20'>
        <div className='tab-item active'>Live Jobs</div>
        <div className='tab-item'>Closed/Archived</div>
      </div>
      <div className='list mt-20'>
        <PostLiveJobItem />
        <PostLiveJobItem />
      </div>
    </>
  )
}

export { JobsPage }
