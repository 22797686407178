import React, { useRef } from 'react'
import { ReactComponent as AddSquare } from '../../assets/img/icon/add-square.svg'
export default function InputFille({
  label,
  customClass = '',
  handleFile,
  text,
}) {
  const hiddenFileInput = useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    handleFile(fileUploaded)
  }
  return (
    <div className={`c-input ${customClass ? customClass : ''}`}>
      <label>{label}</label>
      <div className='c-input-con'>
        <button className='button-upload' onClick={handleClick}>
          {text}
          <AddSquare />
        </button>
        <input
          type='file'
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }} // Make the file input element invisible
        />
      </div>
    </div>
  )
}
