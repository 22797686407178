import React from 'react'

export default function TextareaEdit({ label, custom = null, value }) {
  return (
    <div className='edit-textarea-inner'>
      <div className='edit-textarea'>
        <label>{label}</label>
        <textarea>{value}</textarea>
      </div>
      {custom}
    </div>
  )
}
