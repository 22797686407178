import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg'
import { ReactComponent as AddSquare } from '../../assets/img/icon/add-square.svg'
function AdminUsersSettings() {
  return (
    <>
      <NavLink className='link-back' to={'/settings'}>
        <ArrowLeft />
        Back to all settings
      </NavLink>
      <table className='g-table'>
        <tr>
          <th>Talent Name/Reference</th>
          <th>Email</th>
          <th>Last Active</th>
          <th>Actoins</th>
        </tr>
        <tr>
          <td>
            <div className='g-table-user'>
              <div className='g-table-user-text'>
                <div className='g-table-user-title'>Johnny Lowe</div>
              </div>
            </div>
          </td>
          <td>hi@johnnylowe.design</td>
          <td>01/06/2024</td>
          <td>
            <div className='t-btn-con'>
              <NavLink>Reset Password</NavLink>
              <NavLink>Delete Account</NavLink>
            </div>
          </td>
        </tr>
      </table>
      <button className='full-btn full-btn--green mt-20'>
        <AddSquare /> Add New Super Admin
      </button>
    </>
  )
}

export { AdminUsersSettings }
