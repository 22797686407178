import React from 'react'

import { NavLink } from 'react-router-dom'
import GButton from './inputs/GButton'
import { ReactComponent as Location } from '../assets/img/icon/location.svg'
import { ReactComponent as Stars } from '../assets/img/icon/stars.svg'
import { ReactComponent as Dislike } from '../assets/img/icon/dislike.svg'
import { ReactComponent as Apply } from '../assets/img/icon/apply.svg'
import { ReactComponent as MessageTyping } from '../assets/img/icon/message-typing.svg'
import { ReactComponent as Hend } from '../assets/img/icon/hend.svg'
import MultiTag from './MultiTag'
export default function AdminNewTalentItem() {
  return (
    <div className='notif-item new-talent'>
      <div className='notif-item-left'>
        <div className='notif-item-img'>
          <img src={require(`../assets/img/companies/aviva-logo.jpg`)} alt='' />
        </div>
        <div className='notif-item-text'>
          <div className='notif-item-text-title'>Aviva Investors</div>
          <div className='notif-item-text-data'>Joined 17/06/2024</div>
        </div>
      </div>

      <NavLink>View in Settings</NavLink>
    </div>
  )
}
