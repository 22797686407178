import React from 'react'
import GButton from './inputs/GButton'
import { NavLink } from 'react-router-dom'
import { ReactComponent as MessageTyping } from '../assets/img/icon/message-typing.svg'
import { ReactComponent as Edit } from '../assets/img/icon/edit.svg'
import { ReactComponent as ClockForward } from '../assets/img/multi-tag/clock-forward.svg'
import { ReactComponent as GraduationCap } from '../assets/img/multi-tag/graduation-cap.svg'
import { ReactComponent as Luggage } from '../assets/img/multi-tag/luggage.svg'
import MultiTag from './MultiTag'
export default function PostLiveJobItem() {
  return (
    <div className='post-job post-job--live'>
      <div className='post-job-text'>
        <div className='post-job-title'>Account Manager, Remote</div>
        <NavLink to='/' className='post-job-link'>
          View Matched Talent
        </NavLink>
      </div>
      <div className='post-job-tag'>
        <MultiTag
          tagIcon={<ClockForward />}
          text={'Type'}
          subtext={'Full time (permanent)'}
        />
        <MultiTag
          tagIcon={<GraduationCap />}
          text={'Qualification'}
          subtext={'Bachelors Degree'}
        />
        <MultiTag text={'Location'} subtext={'Hybrid'} />
        <MultiTag
          tagIcon={<Luggage />}
          text={'Sector'}
          subtext={'Accounting'}
        />
      </div>
      <div className='post-job-views'>
        <div className='post-job-views-num'>0</div>
        <div className='post-job-views-label'>Views</div>
      </div>
      <div className='post-job-btns'>
        <GButton text={'Edit'} customIcon={<Edit />} />
        <GButton text={'Preview'} />
        <GButton text={'Message AMP'} customIcon={<MessageTyping />} />
      </div>
    </div>
  )
}
